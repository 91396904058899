<template>
	<v-layout fill-height column>
		<v-flex grow>
			<v-list>
				<v-list-tile v-for="(informationRequest, index) in visibleItems" :key="index">
					<TasksManagerTask :value="informationRequest" no-action />
				</v-list-tile>
			</v-list>
		</v-flex>
		<v-flex shrink>
			<v-layout row justify-center align-center>
				<v-pagination v-if="displayPagination" v-model="page" total-visible="5" :length="totalPages" />
			</v-layout>
		</v-flex>
	</v-layout>
</template>

<script>
export default {
	name: 'TasksManagerActionImportTasksListPreview',
	components: {
		TasksManagerTask: () => ({
			component: import('@/components/TasksManager/TasksManagerTask')
		})
	},
	props: {
		informationRequestsList: {
			required: true,
			type: Array
		}
	},
	data: function () {
		return {
			offset: 5,
			page: 1
		}
	},
	computed: {
		displayPagination: function () {
			return this.totalPages > 1
		},
		totalPages: function () {
			return Math.ceil(this.informationRequestsList.length / this.offset)
		},
		visibleItems: function () {
			return this.displayPagination ? this.informationRequestsList.slice((this.page - 1) * this.offset, this.page * this.offset) : this.informationRequestsList
		}
	}
}
</script>
